<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 탱크수불내역 조회 --------------------------------------------------------------------------------------------->

    <BCard header-tag="header" footer-tag="footer">

      <div slot="header">
        <BIconServer/> <strong> 탱크 입출고 조회 </strong>
        <div class="card-header-actions">
          <small class="text-muted">입출고 내역을 조회/관리 합니다</small>
        </div>
      </div>

      <BRow class="mb-2">
        <BCol sm="2">
          <BInputGroup size="sm">
            <BFormSelect size="sm" v-model="tid" :options="tankOpts" @input="getInoutData" style="background-color:#2f303a"/>
            <BInputGroupAppend>
              <BFormSelect size="sm" v-model="sts" :options="IoStsOpts" style="background-color:#2f303a"
                           @input="getInoutData"/>
            </BInputGroupAppend>


          </BInputGroup>
        </BCol>
        <BCol sm="3">

          <BInputGroup size="sm">
            <BFormSelect size="sm" v-model="volUnit" :options="con.unitVolOpts" style="background-color:#2f303a" @input="getInoutData"/>
            <BFormSelect size="sm" v-model="lenUnit" :options="con.unitLenOpts" style="background-color:#2f303a" @input="getInoutData"/>
            <BFormSelect size="sm" v-model="tmpUnit" :options="con.unitTmpOpts" style="background-color:#2f303a" @input="getInoutData"/>
          </BInputGroup>
        </BCol>
        <BCol>
          <BInputGroup size="sm">
            <BFormInput type="date" v-model="fromDts" @input="getInoutData"></BFormInput>
            <BFormInput type="date" v-model="toDts" @input="getInoutData"></BFormInput>
          </BInputGroup>
        </BCol>
        <BCol sm="2" class="text-right">
          <BInputGroup size="sm">
            <BFormSelect size="sm" v-model="$store.state.numOfInoutItem" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
            <BInputGroupAppend>
              <BButtonGroup>
                <BButton size="sm" variant="primary" @click="getInoutData"><BIconArrowRepeat/></BButton>
                <BButton size="sm" variant="info"
                         @click="()=>{this.downloadModalShow = true}"
                >
                  <BIconFileSpreadsheetFill/>
                </BButton>
              </BButtonGroup>
            </BInputGroupAppend>
          </BInputGroup>
        </BCol>
      </BRow>

      <BRow>
        <BCol v-if="rows.length">
          <vue-excel-editor
            v-model="rows"
            ref="excelGrid"
            width="100%"
            class="mb-1"
            :page="$store.state.numOfInoutItem"
            :readonly-style="{backgroundColor:'#4F5040'}"
            @select="selectRecord"
            @update="updateCell"
            :localized-label="editorLabel"
            :key="editorKey"
            filter-row>
            <vue-excel-column field="_id"          type="string"   key-field invisible/>
            <vue-excel-column field="bgDt"         type="string"   width="140px" :label="cols['bgDt']" :to-text="toLocalTime" readonly/>
            <vue-excel-column field="edDt"         type="string"   width="140px" :label="cols['edDt']" :to-text="toLocalTime" readonly/>
            <vue-excel-column field="tcd"          type="string"   width="80px" :label="cols['tcd']" readonly/>
            <vue-excel-column field="tnm"          type="string"   width="80px" :label="cols['tnm']" readonly/>
            <vue-excel-column field="sts"          type="map"     width="80px" :label="cols['sts']" :options="IoStsMap" readonly/>
            <vue-excel-column field="targetVol"    type="number"  width="90px" :label="`${cols['targetVol']}_${unitSign(volUnit)}`" :to-text="toCommaInt" readonly/>
            <!--                <vue-excel-column field="tid"        type="string"   width="80px" :label="cols['tid']" readonly/>-->
            <!--                <vue-excel-column field="use"        type="string"   width="80px" :label="cols['use']" readonly/>-->
            <vue-excel-column field="isDone"       type="map"      width="65px" :label="cols['isDone']" :options="{true:'완료', false:'작업중'}" readonly/>
            <vue-excel-column field="bgOhr"        type="number"   width="100px" :label="`${cols['bgOhr']}_${unitSign(lenUnit)}`" :to-text="lenUnit==='i'?toCommaFix2:toCommaInt" readonly/>
            <vue-excel-column field="bgOvm"        type="number"   width="100px" :label="`${cols['bgOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
<!--            <vue-excel-column field="bgOvc"        type="number"   width="80px" :label="cols['bgOvc']" :to-text="toCommaInt" readonly/>-->
            <vue-excel-column field="bgAvgTm"      type="number"   width="100px" :label="`${cols['bgAvgTm']}_${unitSign(tmpUnit)}`" readonly/>

            <vue-excel-column field="adjOv"        type="number"   width="100px" :label="`${cols['adjOv']}_${unitSign(volUnit)}`"/>
            <vue-excel-column field="inOvm"        type="number"   width="100px" :label="`${cols['inOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
            <vue-excel-column field="outOvm"        type="number"   width="100px" :label="`${cols['outOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
            <vue-excel-column field="adjRemark"    type="string"   width="120px" :label="cols['adjRemark']"/>
            <vue-excel-column field="opRemark"     type="string"   width="120px" :label="cols['opRemark']"/>


            <vue-excel-column field="edOhr"        type="number"   width="100px" :label="`${cols['edOhr']}_${unitSign(lenUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
            <vue-excel-column field="edOvm"        type="number"   width="100px" :label="`${cols['edOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
            <vue-excel-column field="edOvc"        type="number"   width="100px" :label="`${cols['edOvc']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
            <vue-excel-column field="edAvgTm"      type="number"   width="100px" :label="`${cols['edAvgTm']}_${unitSign(tmpUnit)}`" readonly/>
            <vue-excel-column field="diffOhr"      type="number"   width="100px" :label="`${cols['diffOhr']}_${unitSign(lenUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
            <vue-excel-column field="diffOvm"      type="number"   width="100px" :label="`${cols['diffOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
<!--            <vue-excel-column field="diffOvc"      type="number"   width="80px" :label="cols['diffOvc']" :to-text="toCommaInt" readonly/>-->



<!--            <vue-excel-column field="inOvc"        type="number"   width="80px" :label="cols['inOvc']" :to-text="toCommaInt" readonly/>-->

            <vue-excel-column field="regId"        type="string"   width="80px" label="등록자"/>
            <vue-excel-column field="updId"        type="string"   width="80px" label="수정자"/>



          </vue-excel-editor>
        </BCol>
      </BRow>

      <BRow>
        <BCol>
        </BCol>
        <BCol>
<!--          <BProgress height="2rem" :value="progCount" :max="totalCount" variant="warning" show-value/>-->
        </BCol>
      </BRow>

      <BCard v-if="row!==null" class="mt-2">
        <BRow>
          <BCol>
            <BBadge variant="info">탱크명</BBadge> <BBadge variant="dark" class="mr-3">{{row.tnm}} ({{row.tcd}})</BBadge>
            <BBadge variant="info">기간</BBadge> <BBadge variant="dark" class="mr-3">{{toLocalTime(row.bgDt)}} ~ {{ toLocalTime(row.edDt) }}</BBadge>
            <BBadge :variant="(row.sts==='I')?'danger':'primary'">{{(row.sts==='I')?'입고량':'출고량'}}</BBadge> <BBadge variant="dark">{{(row.sts==='I')? toCommaInt(row.inOvm): toCommaInt(row.outOvm)}}</BBadge>
            <small class="ml-5 text-muted"> 그래프는 시작 전 후 10분이 더 표시됩니다.</small>

          </BCol>
        </BRow>

        <BRow>
          <BCol>

            <GraphInout :tid="row.tid"
                        :fromDts="row.bgDt"
                        :toDts="row.edDt"
                        :volUnit="volUnit"
                        :key="graphKey"
            />

          </BCol>
        </BRow>
      </BCard>


    </BCard>

    <CModal ref="download-modal"
            color="warning"
            title="다운로드 사유 입력"
            :show.sync="downloadModalShow">
      <BInputGroup>
        <BFormInput v-model="downloadReason" debounce="6000" placeholder="5자이상 입력"></BFormInput>
        <BInputGroupAppend>
          <BButton variant="outline-danger" block @click="exportExcel"><BIconDownload/> 다운로드</BButton>
        </BInputGroupAppend>
      </BInputGroup>
    </CModal>



  </div>
</template>

<style src="spinkit/spinkit.min.css"></style>

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';

import {
  accessLogging,
  apiCall, iosDtToLocal, unitSign
} from '@/common/utils';
import qs from 'querystring';
import moment from "moment";
import con, {ExcelEditorLabel} from '@/common/constants'
import GraphInout from '../components/GraphInout';
import {InoutColMap} from "@/common/schema";
import {IoOpts, IoStsMap, IoStsOpts} from "@/common/constants";

// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "TankRealTime",
  components: {
    GraphInout
  },
  data () {
    return {
      graphKey: 0,
      con,
      volUnit: this.$store.state.volUnit,
      lenUnit: this.$store.state.lenUnit,
      tmpUnit: this.$store.state.tmpUnit,
      fromDts: moment().format('YYYY-MM-DD'),
      toDts: moment().format('YYYY-MM-DD'),
      row: null,
      editorKey: 0,
      sts: '',
      arCode: '',
      tanks: [],
      tid: '',
      cols: InoutColMap,
      IoOpts,
      IoStsOpts,
      IoStsMap,
      tankOpts : [],
      tankCodes: this.$store.state.tanks['codes'],

      tank : null,
      tlgPacket: {},
      tankInstances : {},

      editorLabel: ExcelEditorLabel,
      downloadModalShow: false,

      downloadReason: '',

      searchField: 'tid',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,
      updateKey: 0,

      modalTitle: '',

      socket: this.$store.state.socket

    }

  },
  async created(){
    try{

      console.log("[TankInOut] created ---------------------");


    }catch(err){
      console.error(err);
    }
  },

  computed: {},

  async mounted() {
    // console.log("--- TankInOut mounting---------------------");
    await this.getTanks();
    await this.getInoutData();


    // below is not work!
  },

  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners

  },

  methods: {
    unitSign,

    async getTanks(){
      try{

        this.tank = null;
        this.tankOpts = [{value: '', text: '전체 탱크'}];
        const r = await apiCall('get', `/api/tank?order=arCode`);
        if(r.code===200){
          r.result.map(t=>{
            this.tanks.push(t);
            this.tankOpts.push({ value: t.tid, text: `[${t.tankCode}] ${t.name}`} );
          })

          // await this.toastInfo(`탱크정보 ${this.tanks.length}건 조회`, 'info');

          // console.log( 'task-data-query-result--->', r.result  );
        }

      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },




    async getInoutData(){
      this.row = null;
      this.rows = [];
      try{
        let qry ;

        this.paramMap = {
          tid: this.tid,
          sts: this.sts,
          fromDts: this.fromDts,
          toDts: this.toDts,
          volUnit: this.volUnit,
          lenUnit: this.lenUnit,
          tmpUnit: this.tmpUnit
        };

        this.$store.state.volUnit =  this.volUnit;
        this.$store.state.lenUnit =  this.lenUnit;
        this.$store.state.tmpUnit =  this.tmpUnit;

        if(!this.volUnit) return alert('부피단위를 선택하세요');
        if(!this.lenUnit) return alert('길이단위를 선택하세요');
        if(!this.tmpUnit) return alert('온도단위를 선택하세요');


        if(this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify( this.paramMap );
        // console.log( "query-string ---------- ", qry );

        const r = await apiCall('get', `/api/inout?${qry}`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`입출고 현황 ${this.rows.length}건 조회됨`, 'info');
          // console.log( 'task-data-query-result--->', r.result  );
        }else{
          await this.toastResult( r );
        }
        // this.$refs['excelGrid'].clearFilter();

      }catch(err){
        console.error( 'getTankList', err );
        this.toastError(err);
      }

    },



    async selectRecord(idx, evt){
      try{
        if(!evt){
          // this.tankFormShow = false;
          this.row = null;
          return;
        }

        this.row = this.$refs['excelGrid'].getSelectedRecords().pop();
        // console.log("selectRecord --->", this.row);
        this.graphKey++;


      }catch(err){
        console.error(err)
      }


    },

    async updateCell(rec){

      let rc = rec.pop();
      if(!rc.keys[0]){
        return;
      }


      // console.log( "updateRecord--------- record.$id--->",rc.$id );
      // console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );
      // console.log( "updateRecord--------- rec[0]|rc --->",rc );
      const idx = this.$refs['excelGrid'].rowIndex[rc.$id];
      // console.log( "updateRecord--------- rowIndex--->",idx );
      // console.log( "updateRecord--------- $refs --->", this.$refs['excelGrid'] );

      // let row = this.$refs['excelGrid'].table[tIndex];
      //
      // console.log( "row ---------> ", row );

      const cols = this.cols;
      let r = null;

      let param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        // console.log( 'old value --->',rc.oldVal, 'new value --->', rc.newVal );

        // console.log( '-----> will be updated to --->', param);
        r = await apiCall("PUT", `/api/inout/${objectId}?volUnit=${this.volUnit}`, param);
        // console.log( r );
        if( r.code===200 ) {
          this.rows[idx] = r.result;
          this.editorKey++;
        }

        // console.log( r );
        await this.toastResult(r, `${cols[fieldName]} 수정`);
        await this.getInoutData();

      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.error( err );
      }

    },

    async exportExcel(){
      // console.log('------------exportExcel----------')
      try{

        const numRecs = this.$refs['excelGrid'].getSelectedRecords().length;

        if(!numRecs){
          this.$refs['download-modal'].hide();
          return await this.alertWarn("선택한 레코드만 파일로 생성됩니다.",'선택 레코드 없음');
        }

        if( !this.downloadReason || this.downloadReason.length < 5 ){
          return await this.alertWarn( "다운로드 사유는 5자 이상 입력해야 합니다.", '사유 없음');
        }


        const {currentRoute} = this.$router;
        const {fields} = this.$refs["excelGrid"];
        const format = 'xlsx';
        const exportSelectedOnly = true;

        const filename = currentRoute.path.split('/').pop()+'-'+moment().format('YYYY-MM-DD');
        const labels = fields.map(i=>{ return i.label })
        const rs = await accessLogging(
          'download',
          currentRoute,
          labels,
          this.downloadReason,
          `레코드수: ${numRecs}, 파일명: ${filename}.${format}`
        );

        if(rs===true){
          this.$refs['excelGrid'].getSelectedRecords().map(i=>{
            i.bgDt = iosDtToLocal(i.bgDt);
            i.edDt = iosDtToLocal(i.edDt);
          })

          this.$refs["excelGrid"].exportTable(format, exportSelectedOnly, filename);
          this.downloadReason = '';
          return true;
        }else{
          await this.alertDanger("ERROR: "+rs.message);
          return false;
        }

      }catch(err){
        console.error( 'exportExcel error', err);
      }finally{
        this.$refs['download-modal'].hide();

      }
    },



  }
}
</script>
